//
// Co3
//
// Copyright:: (c) 2025 Innotronic Ingenieurbüro GmbH

import { StreamActions } from "@hotwired/turbo"

StreamActions.focus = function()
{
  const targetSelector = this.getAttribute( "target" );
  const target = document.getElementById( targetSelector );
  if( target ) target.focus();
}

StreamActions.redirect = function()
{
  const location = this.getAttribute( "location" );
  Turbo.visit( location );
}
